import React from "react";
import Layout from "../../other/layout";
import SEO from "../../other/seo";
import { PREVIEW } from "../../project-config";

export default function Covid() {
  return (
    <Layout>
      <SEO title="Covid" />

      <div className="covid-page-wrapper">
        <h1 className="categoryTitle">Coronavirus (COVID-19) Updates</h1>
        <div className="covid-page-content">
          <div className="privacyQuestion">
            <p>
              Coronavirus (COVID-19) is a rapidly evolving situation. We realize
              communication is needed now more than ever as we navigate this
              global issue together. This page will serve as a streamlined way
              for us to provide you with updates.
            </p>
            <br></br>
            <h2>Message from DFS’s President</h2>
            <p>
              {/* <a
                href={`${PREVIEW}/store/20090722001/assets/pdf/coronavirus-cda-letter-fred-collins.pdf`}
                target="_blank"
              > */}
              Read the letter {/* (opens in new window) */}
              {/* </a> */} from DFS’s President, Fred Collins to learn how we
              are responding to this global pandemic.
            </p>
            <h2>Shipping Update</h2>
            <p>
              We realize province and local policies are being issued and
              changing by the day. To make sure your order isn’t delayed, please
              ship purchases to an address where someone is available to sign
              off on delivery, instead of shipping to a business address that
              might experience a temporary closure. Please{" "}
              <a href="https://www.dfsonline.ca/contact/ ">contact us</a> if you
              have specific questions on your order status.
            </p>
            <h2>
              Update – Change in Operating Hours for DFS Customer Care Centre
            </h2>
            <p>
              To better support our employees and serve our customers, we have
              adjusted our call centre hours of operation to 8:30 – 5:00 eastern
              time. You can place orders 24/7 at DFSonline.ca or send us an
              email at{" "}
              <a href="mailto:printing@DFSbusiness.com">
                printing@DFSbusiness.com
              </a>
              . We will return to our normal hours of operation as soon as we
              are able.
            </p>
            <h2>Access Account Information</h2>
            <p>
              <a href="https://www.dfsonline.ca/shop/signin.html?vid=20090722001&amp;mt=1">
                Registering for an online account
              </a>{" "}
              is an easy way to access your account information in real-time.
              Your online account allows you to:
            </p>
            <ul
              style={{
                marginLeft: "40px",
                marginTop: "5px",
                listStyleType: "disc"
              }}
            >
              <li>Signup for electronic billing (eBilling)</li>
              <li>Fast quotes and easy reordering</li>
              <li>Track UPS shipment orders</li>
              <li>Print invoices</li>
            </ul>
            <h2>Business Resources</h2>
            <ul
              style={{
                marginLeft: "40px",
                marginTop: "5px",
                listStyleType: "disc"
              }}
            >
              <li>
                Beware:{" "}
                {/* <a
                  href={`${PREVIEW}/store/20090722001/assets/pdf/dfs-canada-covid-19-scam-sheet.pdf`}
                > */}
                Six COVID-19 Scams
                {/* </a> */}
              </li>
              <li>
                <a
                  href="https://www.reuters.com/article/smallbiz-loans-canada/canadian-banks-to-hand-out-interest-free-loans-to-small-businesses-idUSL3N2BX3DG"
                  target="_blank"
                >
                  Canadian banks to hand out interest-free loans to small
                  businesses (opens in new window)
                </a>
              </li>
              <li>
                The Government of Canada has taken significant and decisive
                action to help Canadians facing hardship as a result of
                COVID-19. You can find information at:
              </li>
              <li style={{ listStyle: "none" }}>
                <ul>
                  <li style={{ listStyle: "circle" }}>
                    <a href="https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html">
                      Health, safety and travel information
                    </a>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    <a href="https://www.canada.ca/en/department-finance/economic-response-plan.html">
                      Information about Canada’s COVID-19 Economic Response Plan{" "}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}
